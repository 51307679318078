
const SAVE_STATES = {
  None: "None",
  Started: "Started",
  Done: "Done"
};
const MAX_PRINT_CODE_SIZE = {
  GIFT_CARD: 6,
  OTHER: 9
};
const PRIORITY_OPTIONS = [
  { title: "None", value: "0" },
  { title: 1, value: "1" },
  { title: 2, value: "2" },
  { title: 3, value: "3" }
];

import axios from "axios";
import { mapState } from "vuex";
import { categoryOption } from "@/constants/add-on-const.js";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import UploadImage from "@/components/UploadImage.vue";

import BaseConfirmModal from "@/components/BaseConfirmModal.vue";
/* ENTER YOUR ENDPOINT HERE */

export default {
  components: {
    Breadcrumbs,
    UploadImage,
    BaseConfirmModal
  },
  data() {
    return {
      image: "",
      uploadURL: "",
      categoryOption: categoryOption,
      breadcrumbs: [{ title: "Add-ons", link: "/add-ons" }],
      addOn: {},
      points: 0,
      expense: 0,
      addOnUsers: [],
      new_user_id: "",
      error_addUser: "",
      MAX_PRINT_CODE_SIZE,
      SAVE_STATES,
      saveState: SAVE_STATES.None,
      max_print_code_length: MAX_PRINT_CODE_SIZE.OTHER,
      confirmDelete: false,
      errors: [],
      priority_options: PRIORITY_OPTIONS,
      s3Folder: process.env.VUE_APP_S3_ADD_ON_FOLDER
    };
  },
  computed: {
    ...mapState("addOns", ["list", "newAddOn", "addOnCustomUsers"])
  },

  created() {
    this.addOn = this.loadAddOn(this.$route.params.id);
    this.points = Number(this.addOn.points).toFixed(2);
    this.expense = Number(this.addOn.expense).toFixed(2);
    if (this.addOn.category === categoryOption.CUSTOM) {
      this.loadUsers(this.addOn);
    }
  },
  methods: {
    changeExpense(expense) {
      this.addOn.expense = expense;
    },
    changePoints(points) {
      this.addOn.points = points;
    },
    async addUser(id, click) {
      this.error_addUser = "";

      try {
        let url =
          process.env.VUE_APP_PIXINGO_SERVER +
          "/api/v3/add-ons/" +
          this.addOn.id +
          "/users";
        let results = await axios.post(url, { user_id: id });
        let newUser = results.data;
        // console.log("got newUser", newUser);
        //   this.addOnUsers = this.addOnUsers.concat(newUser);
        await this.loadUsers(this.addOn);
        this.new_user_id = "";
        //   console.log("addOnUsers", this.addOnUsers);
      } catch (err) {
        console.log("got an err", err.response.data);
        this.error_addUser = err.response.data;
      }
    },
    async getOwner(id) {
      console.log("get owner for id", id);
      if (!id) {
        return;
      }
      try {
        let url = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/users/" + id;
        let results = await axios.get(url);
        let output = Object.assign({}, results.data);
        output.text =
          [output.first_name, output.second_name].join(" ") +
          (output.company ? " - " + output.company : "") +
          " (" +
          output.bydesign_id +
          ")";
        // console.log(output, results.data);
        return output;
      } catch (err) {
        console.log("got an error", err);
      }
    },

    changeCategory(category) {
      //   console.log("change to category", category, categoryOption.CUSTOM);
      if (category === categoryOption.CUSTOM) {
        this.loadUsers(this.addOn);
      }
      this.max_print_code_length =
        category === categoryOption.GIFT_CARD
          ? MAX_PRINT_CODE_SIZE.GIFT_CARD
          : MAX_PRINT_CODE_SIZE.OTHER;
      this.clearVariableOptions();
    },
    async loadUsers(addOn) {
      try {
        // console.log("loading users");
        let url =
          process.env.VUE_APP_PIXINGO_SERVER +
          "/api/v3/add-ons/" +
          addOn.id +
          "/users";
        let results = await axios.get(url);
        this.addOnUsers = results.data;
        // console.log("got users", this.addOnUsers);
      } catch (err) {
        console.log("err", err);
      }
    },
    clearVariableOptions() {
      this.addOn.gift_card_amounts = "";
      this.addOn.owner_user_id = 0;
      this.addOn.points = 0;
      this.addOn.expense = 0;
      this.changePoints(0);
      this.changeExpense(0);
      this.addOn.description = "";
      this.addOn.print_code = "";
    },
    loadAddOn(id) {
      const _id = typeof id === "string" ? parseInt(id) : id;

      let found = this.list.find(item => item.id === _id);

      if (found) {
        return Object.assign({}, found);
      }
      return Object.assign({}, this.newAddOn);
    },
    validate(addOn) {
      if (!addOn.visible) {
        return [];
      }
      switch (addOn.category) {
        case categoryOption.GIFT:
          return this.validateGift(addOn);
        case categoryOption.GIFT_CARD:
          return this.validateGiftCard(addOn);
        case categoryOption.CUSTOM:
          return this.validateCustom(addOn);
      }
    },
    validateGift(addOn) {
      let fields = [
        "image_url",
        "title",
        "print_code",
        "description",
        "points",
        "expense"
      ];
      return this.validateFields(addOn, fields);
    },
    validateGiftCard(addOn) {
      let fields = ["image_url", "title", "print_code", "gift_card_amounts"];
      return this.validateFields(addOn, fields);
    },
    validateCustom(addOn) {
      let fields = ["image_url", "title", "print_code", "description"];
      let errors = this.validateFields(addOn, fields);
      let total =
        Number.parseFloat(addOn.points) + Number.parseFloat(addOn.expense);
      console.log("got total", total);
      if (total <= 0) {
        errors.push("Invalid price");
      }

      return errors;
    },
    validateFields(addOn, fields) {
      let errors = [];
      fields.forEach(field => {
        if (!addOn[field] || addOn[field] == 0) {
          errors.push("Missing: " + field);
        }
      });

      return errors;
    },
    async save() {
      this.saveState = SAVE_STATES.Started;
      this.errors = this.validate(this.addOn);

      if (this.errors.length) {
        return;
      }

      try {
        if (this.addOn.id) {
          let url =
            process.env.VUE_APP_PIXINGO_SERVER +
            "/api/v3/add-ons/" +
            this.addOn.id;
          await axios.put(url, this.addOn);
          this.$store.commit("addOns/update", this.addOn);
        } else {
          let url = process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/add-ons/";
          let results = await axios.post(url, this.addOn);
          this.addOn.id = results.data.id;
          this.$store.commit("addOns/create", this.addOn);
        }
        this.saveState = SAVE_STATES.Done;
      } catch (err) {
        console.log("error saving", err.response.data);
      }
    },
    async deleteAddOn(addOn) {
      try {
        let url =
          process.env.VUE_APP_PIXINGO_SERVER + "/api/v3/add-ons/" + addOn.id;
        let results = await axios.delete(url);
        this.$store.commit("addOns/delete", addOn);
        this.$router.push("/add-ons");
      } catch (err) {
        console.log("error deleting", err.response.data);
      }
    },
    async removeUser(user) {
      try {
        let url =
          process.env.VUE_APP_PIXINGO_SERVER +
          "/api/v3/add-ons/" +
          user.add_on_id +
          "/users/" +
          user.user_id;
        let results = await axios.delete(url);
        this.addOnUsers = this.addOnUsers.filter(
          item => item.map_id !== user.map_id
        );
        console.log("Removed user", user);
      } catch (err) {
        console.log("error deleting", err.response.data);
      }
    },
    noop() {},
    onUploadedImage(event) {
      console.log("onupload image", event);
      this.addOn.image_url = event.image_url;
    },
    async handleConfirmDelete() {
      await this.deleteAddOn(this.addOn);
      this.confirmDelete = false;
    },
    handleCancelDelete() {
      console.log("cancel delete");
      this.confirmDelete = false;
    },
    handlePriorityChange(event) {
      this.addOn.priority = event.value;
    }
  }
  //Look up the item and create a copy that can be edited.
};
//<style lang="scss" scoped></style>
