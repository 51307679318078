
export default {
  props: {
    options: {
      type: Array,
      default: []
    },
    selected: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      show: false,
      _selected: 0
    };
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this._selected);
    }
  },
  created() {
    this._selected = this.selected;
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
  methods: {
    close(event) {
      this.show = false;
    },
    selectOption(option) {
      this._selected = option.value;
      this.show = false;
      this.$emit("change", option);
    }
  }
};
