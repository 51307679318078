
import { categoryOption } from "@/constants/add-on-const";
import PointsIcon from "@/components/PointsIcon.vue";
import ExpenseIcon from "@/components/ExpenseIcon.vue";

export default {
  components: {
    ExpenseIcon,
    PointsIcon
  },

  props: {
    item: {
      type: Object,
      default: {}
    }
  },

  data() {
    return { categoryOption: categoryOption };
  },
  created() {},
  mounted() {}
};
