
import AddOnList from "@/components/AddOnList.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
    Breadcrumbs,
    AddOnList
  }
};
