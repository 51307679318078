
const FILTER_ALL = "All";
import { mapState } from "vuex";
import AddOnListItem from "@/components/AddOnListItem.vue";
import { categoryOption } from "@/constants/add-on-const.js";

export default {
  components: {
    AddOnListItem
  },
  data() {
    return {
      filterOptions: [
        { title: "All", value: FILTER_ALL },
        { title: "Gifts", value: categoryOption.GIFT },
        { title: "Gift Cards", value: categoryOption.GIFT_CARD },
        { title: "Custom", value: categoryOption.CUSTOM }
      ],
      selectedFilter: FILTER_ALL,
      search: ""
    };
  },

  computed: {
    ...mapState("addOns", ["list"]),
    filteredList() {
      //   console.log("this.selectedFilter", this.selectedFilter);
      let list =
        this.selectedFilter === FILTER_ALL
          ? this.list
          : this.list.filter(item => item.category === this.selectedFilter);
      if (this.search) {
        list = list.filter(item =>
          item.title.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return list;
    }
  },

  async created() {
    await this.$store.dispatch("addOns/getAll");
  },

  methods: {
    handleFilterChange(value) {
      //   console.log("value", value);
      this.selectedFilter = value.value;
    }
  }
};
